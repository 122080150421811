import React, { useState } from 'react';
import { addSubscription } from '../../services/kaarwanServices';

const EmailSubscriptionVer2 = ({ subTitle }) => {
  const [email, setEmail] = useState('');
  const [result, setResult] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const subHeading = subTitle
    ? subTitle
    : `Be the first to know about our new
                <b className='text-white'>
                  <i>Blogs, Courses & Masterclasses!</i>
                </b>`;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (result) return;
    setIsProcessing(true);
    return addSubscription({ email }).then(({ data }) => {
      if (data.code === 200) setResult(true);
      setIsProcessing(false);
    });
  };

  return (
    <div className='email-subscriber-ver2 overflow-hidden'>
      <div className='container'>
        <div className='main-section m-auto'>
          <div className='row g-5 d-flex align-items-center'>
            <div className='col-lg-6 col-12'>
              <h6 className='text-white title'>Subscribe to Our Newsletter For Weekly Update 🚀</h6>
              <p className='text-white m-0' dangerouslySetInnerHTML={{ __html: subHeading }}>
                {}
              </p>
            </div>
            <div className='col-lg-6 col-12'>
              <form method='post' onSubmit={handleSubmit} className='form-card d-flex flex-column align-items-center'>
                <input
                  type='email'
                  placeholder='Enter your Email address'
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
                <button type='submit' className='w-100'>
                  {isProcessing ? (
                    <span className='dotted-loader'>
                      <div className='spinner'>
                        <div className='bounce1'></div>
                        <div className='bounce2'></div>
                        <div className='bounce3'></div>
                      </div>
                    </span>
                  ) : (
                    <>{result ? 'Thank you for Subscribing!' : 'Get Weekly Updates for Free!'}</>
                  )}
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailSubscriptionVer2;
