import React from 'react';

const ArchitectureIcon = () => {
  return (
    <svg width='16' height='21' viewBox='0 0 16 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.9228 18.7207C15.4343 18.3116 15.639 17.5954 15.332 16.9816L11.6492 7.16091C11.9561 6.75175 12.0584 6.24026 12.1607 5.5241C12.3654 4.29658 11.6492 3.06896 10.5239 3.06896H9.19408V1.02299C9.19408 0.409158 8.78492 0 8.1711 0C7.55727 0 7.14811 0.409158 7.14811 1.02299V3.06896H5.8183C4.69298 3.06896 3.97685 4.39877 4.18148 5.5241C4.18148 6.24026 4.386 6.75175 4.69298 7.16091L1.01022 16.9816C0.805566 17.5954 1.01022 18.3114 1.41938 18.7207L1.01022 19.7437C0.907894 20.0506 1.01022 20.2552 1.31706 20.3576C1.41939 20.3576 1.82855 20.4599 1.93089 20.0507L2.34005 19.0277C2.95388 19.0277 3.5677 18.7209 3.77219 18.1071L7.25031 8.6956H8.06863H8.88695L12.3651 18.1071C12.5697 18.7209 13.1834 19.1301 13.7972 19.0277L14.2064 20.0507C14.3087 20.2554 14.5132 20.4599 14.8202 20.3576C15.127 20.2552 15.2294 19.9484 15.127 19.7437L14.9228 18.7207ZM8.1711 6.44489C7.55727 6.44489 7.14811 6.03573 7.14811 5.42191C7.14811 4.80808 7.55727 4.39892 8.1711 4.39892C8.78492 4.39892 9.19408 4.80808 9.19408 5.42191C9.19408 5.9334 8.68259 6.44489 8.1711 6.44489Z'
        fill='#E35D96'
      />
    </svg>
  );
};

export default ArchitectureIcon;
