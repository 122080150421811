import React, { useState, useEffect } from 'react';
// import NewHeader from './NewHeader';
import MobileHeader from './MobileHeader';
import { useDispatch, useSelector } from 'react-redux';
import { selectCategories, updateReduxSearchData } from '../../../redux/slice/categoriesSlice';
import DesktopHeaderVer2 from './DesktopHeaderVer2';

// eslint-disable-next-line no-unused-vars
const Header = ({ classes, showSearchIcon, showAccountIcon, logoStyle, isDark, setBgBlur }) => {
    const [categories, setCategories] = useState([]);
    const [trips, setTrips] = useState([]);

    const { data: catData, success: categoriesSuccess } = useSelector(selectCategories);

    useEffect(() => {
        if (categoriesSuccess) {
            if (catData) {
                if (catData?.courseCategories && catData?.courseCategories?.length) {
                    setCategories(catData?.courseCategories);
                }

                let _upcoming = [];
                let _previous = [];
                if (catData?.upcomingTrips && catData?.upcomingTrips?.length) {
                    Array.from(catData?.upcomingTrips).forEach((item) => {
                        if (item?.id && item?.name) {
                            _upcoming.push({
                                id: item?.id,
                                name: item?.name || '',
                                startDate: item?.startDate || '',
                                endDate: item?.endDate || '',
                                url: item?.url || '',
                                image: item?.image || '',
                                thumbnail: item?.thumbnail || ''
                            });
                        }
                    });
                }

                if (catData?.previousTrips && catData?.previousTrips?.length) {
                    Array.from(catData?.previousTrips).forEach((item) => {
                        if (item?.id && item?.name) {
                            _previous.push({
                                id: item?.id,
                                name: item?.name || '',
                                startDate: item?.startDate || '',
                                endDate: item?.endDate || '',
                                url: item?.url || '',
                            });
                        }
                    });
                }
                _upcoming = _upcoming.sort((a, b) => new Date(a?.startDate) - new Date(b?.startDate));
                _previous = _previous.sort((a, b) => new Date(b?.endDate) - new Date(a?.endDate));
                if (_upcoming.length) {
                    setTrips(_upcoming)
                } else {
                    setTrips([])
                }
            }
        }
    }, [categoriesSuccess, catData]);


    const { masterclasses } = useSelector((state) => state.masterclasses);
    const { courses } = useSelector((state) => state.courses);

    const dispatch = useDispatch();


    const {
        success,
        data,
    } = useSelector((state) => state.categories);



    useEffect(() => {
        if (success) {
            if (data && data.courseCategories && data.courseCategories.length) {
                const _courses = [];
                Array.from(data?.courseCategories).forEach((cat) => {
                    if (cat?.Subcategory && cat?.Subcategory.length) {
                        Array.from(cat?.Subcategory).forEach((subCat) => {
                            if (subCat?.Workshops && subCat?.Workshops.length) {
                                Array.from(subCat?.Workshops).forEach((ws) => {
                                    _courses.push({
                                        catId: cat.id,
                                        catName: String(cat.name).toLowerCase(),
                                        subCatId: subCat.id,
                                        subCatName: String(subCat.name).toLowerCase(),
                                        id: ws?.id,
                                        days: ws?.days,
                                        hours: ws?.hours,
                                        type: ws?.type,
                                        url: ws?.url,
                                        shortTitleType: ws?.shortTitleType,
                                        shortTitle: ws?.shortTitle ? ws?.shortTitle : null,
                                        name: ws?.name,
                                        batchId: (ws?.Batches && ws?.Batches.length > 0 && ws?.Batches[0]?.id) || 0,
                                        status: (ws?.Batches && ws?.Batches.length > 0 && ws?.Batches[0]?.status) || 0,
                                    });
                                });
                            }
                        });
                    }
                });
            }
        }
    }, [success, data]);

    useEffect(() => {
        if (success) {
            let _uniqueMasterclasses = [];
            let _uniqueCourses = [];

            if (masterclasses.length && courses.length) {
                _uniqueMasterclasses = Array.from(new Set(masterclasses.map((item) => item?.id))).map((id) =>
                    masterclasses.find((item) => item?.id === id)
                );
                _uniqueCourses = Array.from(new Set(courses.map((item) => item?.id))).map((id) =>
                    courses.find((item) => item?.id === id)
                );

                dispatch(updateReduxSearchData([..._uniqueCourses, ..._uniqueMasterclasses]));
            }
        }
    }, [success, masterclasses, courses, dispatch]);

    return (
        <>
            <div className='desktop-header'>
                <DesktopHeaderVer2
                    categories={categories}
                    classes={classes}
                    isDark={isDark}
                    trips={trips}
                    setBgBlur={setBgBlur}
                />
            </div>
            <div className='mobile-header'>
                <MobileHeader
                    showSearchIcon={showSearchIcon}
                    showAccountIcon={showAccountIcon}
                    classes={classes}
                    categories={categories}
                    trips={trips}
                />
            </div>
        </>
    );
};

export default Header;
