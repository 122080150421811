import React, { useState, useContext } from 'react';
import { Accordion } from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';
import AccordionContext from 'react-bootstrap/AccordionContext';

const faqCategories = [
    {
        id: 1,
        slug: 'workshop-collaterals',
        label: 'Certificates & Recordings',
    },
    {
        id: 2,
        slug: 'missed-session',
        label: "Couldn't attend a Workshop?",
    },
    {
        id: 3,
        slug: 'payment-related',
        label: 'Fees and Payment Related',
    },
    {
        id: 4,
        slug: 'workshop-details',
        label: 'Details of the Workshop',
    },
    {
        id: 5,
        slug: 'workshop-structure',
        label: 'Workshop Related Queries',
    },
];

const workshopCollaterals = {
    leftColumn: [
        {
            id: 1,
            title: 'Will we get a certificate for attending the workshop?',
            body: 'Yes, we do provide certificates to all our participants for attending the workshop. The certificates are mailed within 15 days from the workshop conclusion date.',
        },
        {
            id: 2,
            title: 'Do you get a different certificate for completing the tasks?',
            body: 'For 2 days and longer workshops, you will get a completion certificate for completing your tasks and assignments, else you will get a participation certificate which would state you attended the workshop',
        },
        {
            id: 3,
            title: 'Do we get recordings of the workshop?',
            body: `We provide recording for some of our 1 or 2 day masterclass with an additional charge. For longer courses, incase you miss any sessions due to an emergency we do provide recordings on the student dashboard.`
        },
    ],
};

const missedSession = {
    leftColumn: [
        {
            id: 1,
            title: 'What do i do if i miss a session?',
            body: 'If you miss a session, and if you inform us within 7 days from the workshop start date, you can be shifted to the next batch with a minimum rescheduling fee',
        },
        {
            id: 2,
            title: "What do i do if I'm unavailable to attend the session? / Can I shift to a later batch?",
            body: 'You can shift to the next batch of the same workshop, if you inform us atleast 4-6 hours before the workshop.',
        },
        {
            id: 3,
            title: 'I was not able to attend a session. Will i get a refund for it?',
            body: 'Workshop fee once paid is non-refundable. We can shift you from one batch to another if informed in advance.',
        },
    ],
};

const paymentRelated = {
    leftColumn: [
        {
            id: 1,
            title: 'How do I know my registration is complete?',
            body: "You can directly register for a workshop from our website. Once your payment is complete you will get a confirmation mail from us with further details.  (In some rare cases, when the payment is completed, but it hasn't reached us, it may take a couple of hours to get the mail. Incase you do not recieve it within 4 hours, please contact 9833777469 or email us at hola@kaarwan.com)",
        },
        {
            id: 2,
            title: 'My money was deducted but I got a failed payment message',
            body: 'If you have recd the failed payment message, your money will be refunded you within 3-4 days. If you have recieved a confirmation mail and a failed payment mail, it means your transaction failed on the first attempt, but the second attempt was sucessful and your registration is confirmed.',
        },
        {
            id: 3,
            title: "I haven't recieved the payment confirmation reciept in the email. Is my seat confirmed?",
            body: "The payment confirmation mail is sent automatically once your payment is recieved on the payment gateway. Incase you dont recieve it please check your spam and promotions folder. If you do not find it anywhere, please write to us on hola@kaarwan.com, and we'll sort it out at the earliest. (In most cases, it is because the email address is entered incorrectly)",
        },
    ],
};

const workshopDetails = {
    leftColumn: [
        {
            id: 1,
            title: 'Which language will the workshop be conducted in?',
            body: 'At present, the primary mode of instruction is English. However, our team understand both hindi and english. We would be happy to answer any questions that you in the language you are most comfortable with.',
        },
        {
            id: 2,
            title: 'Is the workshop online or offline?',
            body: 'All workshops and courses (except travels) are conducted online on Zoom. ',
        },
        {
            id: 3,
            title: 'Is the workshop conducted live or recorded?',
            body: `Our advance course offers a dynamic blend of learning experiences. You'll have access to practice files for self-paced learning + additional resources throughout the week. Live sessions, scheduled on specific workshop days, provide opportunities for mentor interaction, query resolution, guest lectures, and hands-on activities.`,
        },
        {
            id: 4,
            title: 'When will I get the session joining link for my registered workshop?',
            body: 'The session joining link is shared 4-6 hours before the workshop start time. ',
        },
    ],
};

const workshopStructure = {
    leftColumn: [
        {
            id: 1,
            title: 'Will there be a QnA at the end of the session? ',
            body: 'Yes there will be a live QnA at the end of all sessions. ',
        },
        {
            id: 2,
            title: 'Will there be a break in the session? ',
            body: 'Yes there will be a for all sessions longer than 1.5 hours.',
        },
        {
            id: 3,
            title: 'I have registered for the workshop, when will i get the details?',
            body: 'The workshop details (including WhatsApp group joining link, task etc are usually emailed 24-48 hours before the workshop.  Please keep an eye on your registered email for the same. (Note: sometimes the mail ends up in promotions or spam folder please check those as well)',
        },
        {
            id: 4,
            title: 'What is included in the job support program?',
            body: `Our job support program is your career booster. It covers key areas like portfolio development, establishing a strong online presence, honing job search techniques, mastering interviews, skill testing, and enhancing your professional communication`,
        },
        {
            id: 5,
            title: `Who can benefit from this program?`,
            body: `The program is for anyone aiming to switch careers or start fresh in any industry, no matter your level of experience.`,
        },
        {
            id: 6,
            title: `How will this program boost my job prospects?`,
            body: `This program equips you to shine in job applications and interviews. It empowers you to communicate effectively, build an impressive portfolio, and opens doors to career growth through direct hiring by our industry partners.`,
        },
    ],
};

const CustomToggle = ({ children, eventKey }) => {
    const { activeEventKey } = useContext(AccordionContext);
    const decoratedOnClick = useAccordionButton(eventKey);
    const isCurrentEventKey = activeEventKey === eventKey;
    return (
        <button
            type='button'
            onClick={decoratedOnClick}
            aria-expanded={isCurrentEventKey ? true : false}
            className='edu-accordion-button'
        >
            {children}
        </button>
    );
};

const GeneralFAQ = () => {
    const [selectedItem, setSelectedItem] = useState('workshop-collaterals');
    const [activeId1, setActiveId1] = useState('0');
    const [activeId3, setActiveId3] = useState('0');
    const [activeId5, setActiveId5] = useState('0');

    function toggleActive1(id) {
        if (activeId1 === id) {
            setActiveId1(null);
        } else {
            setActiveId1(id);
        }
    }

    function toggleActive3(id) {
        if (activeId3 === id) {
            setActiveId3(null);
        } else {
            setActiveId3(id);
        }
    }

    function toggleActive5(id) {
        if (activeId5 === id) {
            setActiveId5(null);
        } else {
            setActiveId5(id);
        }
    }

    return (
        <>
            <div
                className={`edu-accordion-area accordion-shape-1 edu-section-gap bg-color-white home-faqs-container kw-section-gap`}
            >
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <div className='section-title text-center'>
                                <span className='pre-title'>Need any help?</span>
                                <h2 className='title'>
                                    Here are some frequently <br /> asked questions
                                </h2>
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-lg-12'>
                            <ul className='edu-tab-nav nav nav-tabs mt--40'>
                                {faqCategories &&
                                    faqCategories.length > 0 &&
                                    faqCategories.map((button) => (
                                        <li className='nav-item' key={button.id}>
                                            <button
                                                className={
                                                    button.slug === selectedItem ? 'nav-link active' : 'nav-link '
                                                }
                                                type='button'
                                                aria-label={button.label}
                                                onClick={() => setSelectedItem(button.slug)}
                                            >
                                                {button.label}
                                            </button>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                    </div>
                    {selectedItem === 'workshop-collaterals' && (
                        <div className='row g-5 align-items-center mt--20'>
                            <div className='col-lg-12'>
                                <div className='accordion-style-1'>
                                    <Accordion bsPrefix='edu-accordion' defaultActiveKey='0' flush>
                                        {workshopCollaterals.leftColumn.map((accordion, i) => (
                                            <Accordion.Item
                                                eventKey={i.toString()}
                                                key={i}
                                                onClick={() => toggleActive1(i.toString())}
                                                bsPrefix={`edu-accordion-item ${activeId1 === i.toString() ? 'active-item' : ''
                                                    }`}
                                            >
                                                <div className='edu-accordion-header'>
                                                    <CustomToggle eventKey={i.toString()}>
                                                        <h3 className='text-black'>{accordion.title}</h3>
                                                    </CustomToggle>
                                                </div>
                                                <Accordion.Body bsPrefix='edu-accordion-body'>
                                                    <h4 className='text-black'>{accordion.body}</h4>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    )}

                    {selectedItem === 'missed-session' && (
                        <div className='row g-5 align-items-center mt--20'>
                            <div className='col-lg-12'>
                                <div className='accordion-style-1'>
                                    <Accordion bsPrefix='edu-accordion' defaultActiveKey='0' flush>
                                        {missedSession.leftColumn.map((accordion, i) => (
                                            <Accordion.Item
                                                eventKey={i.toString()}
                                                key={i}
                                                onClick={() => toggleActive3(i.toString())}
                                                bsPrefix={`edu-accordion-item ${activeId3 === i.toString() ? 'active-item' : ''
                                                    }`}
                                            >
                                                <div className='edu-accordion-header'>
                                                    <CustomToggle eventKey={i.toString()}>
                                                        <h3 className='text-black'>{accordion.title}</h3>
                                                    </CustomToggle>
                                                </div>
                                                <Accordion.Body bsPrefix='edu-accordion-body'>
                                                    <h4 className='text-black'>{accordion.body}</h4>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    )}

                    {selectedItem === 'payment-related' && (
                        <div className='row g-5 align-items-center mt--20'>
                            <div className='col-lg-12'>
                                <div className='accordion-style-1'>
                                    <Accordion bsPrefix='edu-accordion' defaultActiveKey='0' flush>
                                        {paymentRelated.leftColumn.map((accordion, i) => (
                                            <Accordion.Item
                                                eventKey={i.toString()}
                                                key={i}
                                                onClick={() => toggleActive5(i.toString())}
                                                bsPrefix={`edu-accordion-item ${activeId5 === i.toString() ? 'active-item' : ''
                                                    }`}
                                            >
                                                <div className='edu-accordion-header'>
                                                    <CustomToggle eventKey={i.toString()}>
                                                        <h3 className='text-black'>{accordion.title}</h3>
                                                    </CustomToggle>
                                                </div>
                                                <Accordion.Body bsPrefix='edu-accordion-body'>
                                                    <h4 className='text-black'>{accordion.body}</h4>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    )}

                    {selectedItem === 'workshop-details' && (
                        <div className='row g-5 align-items-center mt--20'>
                            <div className='col-lg-12'>
                                <div className='accordion-style-1'>
                                    <Accordion bsPrefix='edu-accordion' defaultActiveKey='0' flush>
                                        {workshopDetails.leftColumn.map((accordion, i) => (
                                            <Accordion.Item
                                                eventKey={i.toString()}
                                                key={i}
                                                onClick={() => toggleActive5(i.toString())}
                                                bsPrefix={`edu-accordion-item ${activeId5 === i.toString() ? 'active-item' : ''
                                                    }`}
                                            >
                                                <div className='edu-accordion-header'>
                                                    <CustomToggle eventKey={i.toString()}>
                                                        <h3 className='text-black'>{accordion.title}</h3>
                                                    </CustomToggle>
                                                </div>
                                                <Accordion.Body bsPrefix='edu-accordion-body'>
                                                    <h4 className='text-black'>{accordion.body}</h4>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    )}

                    {selectedItem === 'workshop-structure' && (
                        <div className='row g-5 align-items-center mt--20'>
                            <div className='col-lg-12'>
                                <div className='accordion-style-1'>
                                    <Accordion bsPrefix='edu-accordion' defaultActiveKey='0' flush>
                                        {workshopStructure.leftColumn.map((accordion, i) => (
                                            <Accordion.Item
                                                eventKey={i.toString()}
                                                key={i}
                                                onClick={() => toggleActive5(i.toString())}
                                                bsPrefix={`edu-accordion-item ${activeId5 === i.toString() ? 'active-item' : ''
                                                    }`}
                                            >
                                                <div className='edu-accordion-header'>
                                                    <CustomToggle eventKey={i.toString()}>
                                                        <h3 className='text-black'>{accordion.title}</h3>
                                                    </CustomToggle>
                                                </div>
                                                <Accordion.Body bsPrefix='edu-accordion-body'>
                                                    <h4 className='text-black'>{accordion.body}</h4>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* </Layout> */}
        </>
    );
};

export default GeneralFAQ;
