import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCategoriesData, selectCategories } from '../../../redux/slice/categoriesSlice';
import Header from './Header';
import { fetchMasterclasses, selectMasterclases } from '../../../redux/slice/masterclassSlice';
import { fetchLongTermCourses, selectCourses } from '../../../redux/slice/coursesSlice';

const CommonNavbar = ({ classes, showSearchIcon, showAccountIcon, logoStyle, isDark, allowBlur = true }) => {
    const dispatch = useDispatch();

    const { success: categoriesSuccess } = useSelector(selectCategories);
    const { success: masterclassSuccess } = useSelector(selectMasterclases);
    const { success: coursesSuccess } = useSelector(selectCourses);

    useEffect(() => {
        if (!categoriesSuccess) {
            dispatch(fetchCategoriesData());
        }
        if (!masterclassSuccess) {
            dispatch(fetchMasterclasses());
        }
        if (!coursesSuccess) {
            dispatch(fetchLongTermCourses());
        }
    }, [categoriesSuccess, masterclassSuccess, coursesSuccess, dispatch]);

    const [scrolled, setScrolled] = useState(false)

    useEffect(() => {
        let lastScrollTop = 77;
        const navbar = document.querySelector('#common_navbar');
        const header = document.querySelector("#kwn-desktop-header-ver2");
        if (navbar && header) {
            window.addEventListener('scroll', () => {
                const lastScroll = window.scrollY;
                if (lastScroll > lastScrollTop) {
                    navbar.style.top = '5px';
                    setScrolled(true)
                }
                if (lastScroll < lastScrollTop) {
                    navbar.style.top = '5px';
                    setScrolled(true)
                }
                if (lastScroll === 0) {
                    header.classList.remove("add-bg-white");
                    navbar.style.top = '0px';
                    setScrolled(false)
                    if (isDark) {
                        header.classList.add("header-dark");
                    }
                } else {
                    header.classList.add("add-bg-white");
                    header.classList.remove("header-dark");
                    setScrolled(true)
                }
                lastScrollTop = lastScroll;
            });
        }
    }, [isDark]);

    const [bgBlur, setBgBlur] = useState(false);

    return (
        <div id='common_navbar' className={`${bgBlur && scrolled && allowBlur ? 'blur-bg' : ''}`}>
            <Header
                classes={classes}
                logoStyle={logoStyle}
                showSearchIcon={showSearchIcon}
                showAccountIcon={showAccountIcon}
                isDark={isDark}
                setBgBlur={setBgBlur}
            />
        </div>
    );
};

export default CommonNavbar;
