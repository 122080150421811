import React from 'react';

const GeneralDesignIcon = () => {
  return (
    <svg width='18' height='20' viewBox='0 0 18 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <mask
        id='mask0_9128_3486'
        // style='mask-type:luminance'
        maskUnits='userSpaceOnUse'
        x='0'
        y='0'
        width='18'
        height='20'
      >
        <path d='M17.182 0H0.818359V20H17.182V0Z' fill='white' />
      </mask>
      <g mask='url(#mask0_9128_3486)'>
        <path
          d='M1.57422 19.5007C3.76445 16.8818 1.71206 13.5214 3.65969 11.2567C3.98053 10.8623 4.3772 10.5362 4.82626 10.2977C5.27531 10.0593 5.76763 9.91329 6.27409 9.86838C6.78055 9.82347 7.29087 9.88056 7.77487 10.0363C8.25887 10.1921 8.70672 10.4433 9.09194 10.7752C9.47717 11.107 9.79193 11.5127 10.0176 11.9684C10.2433 12.4239 10.3754 12.9202 10.4059 13.4277C10.4365 13.9353 10.365 14.4437 10.1957 14.9232C10.0263 15.4026 9.76249 15.8432 9.41989 16.2188C8.48638 17.373 7.28073 18.2772 5.91128 18.85C4.54182 19.4229 3.05149 19.6465 1.57422 19.5007Z'
          fill='#838CE7'
        />
        <path
          d='M9.57991 12.2274L15.8761 4.93237C16.107 4.62008 16.2706 4.2633 16.3566 3.88455C16.4427 3.50582 16.4493 3.11335 16.3759 2.73194C16.3026 2.35053 16.151 1.98847 15.9306 1.66863C15.7104 1.34879 15.4261 1.07811 15.0958 0.873689C14.7656 0.669271 14.3966 0.535555 14.012 0.480988C13.6275 0.426421 13.2358 0.452187 12.8617 0.55666C12.4876 0.661133 12.1393 0.842042 11.8386 1.08796C11.5381 1.33388 11.2917 1.63947 11.1152 1.98542L6.99023 10.4513'
          fill='#838CE7'
        />
      </g>
    </svg>
  );
};

export default GeneralDesignIcon;
