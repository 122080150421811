import React from 'react';
import moment from 'moment';
import Link from 'next/link';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { getPaymentUrl, getPrices, getShortTitle, getWorkshopURL, getWsPricing } from '../../utils';
import { selectCurrency } from '../../redux/slice/currencySlice';

const CourseCardTwo = ({ data }) => {
    const { currency: _currencyType } = useSelector(selectCurrency);

    const { _INR_PRICE, _USD_PRICE, _SLASH_DOLLAR, _SLASH_AMOUNT, _IS_RECORDED } = getPrices(data);

    const _VALUE = _currencyType === 'INR' ? _INR_PRICE : _USD_PRICE;
    const _CURRENCY = _currencyType === 'INR' ? 'INR' : 'USD';

    const date =
        moment(data?.Batches && data?.Batches[0]?.startDate)
            .format('MMM DD')
            .toString()
            .toLowerCase() === 'invalid date'
            ? 'Coming soon'
            : moment(data?.Batches[0]?.startDate).format('MMM DD');

    const handleEvent = () => {
        !!window.fbq &&
            window.fbq('track', 'AddToCart', {
                content_name: data?.name,
                content_type: 'Product',
                value: _VALUE,
                currency: _CURRENCY,
            });
        window.gtag('event', 'add_to_cart', {
            currency: _CURRENCY,
            value: _VALUE,
            items: [
                {
                    item_name: data?.name,
                    price: _VALUE,
                    quantity: 1,
                },
            ],
            event_category: 'button',
            event_label: 'add_to_cart',
        });
    };

    const COUPON = data?.CouponDetails && data?.CouponDetails.length ? data?.CouponDetails[0] : null;

    const router = useRouter();
    let _params = '';
    Object.entries(router.query)
        .filter((item) => item[0] !== 'paymentId')
        .filter((item) => item[0] !== 'id')
        .forEach((item) => {
            _params += `&${item[0]}=${item[1]}`;
        });

    return (
        <div className='course-card-2'>
            <div className='card-thumb'>
                <Link href={getWorkshopURL(data?.type, data?.name, data?.id, data?.url, _params)}>
                    <a>
                        <div className='image-wrapper'>
                            <Image
                                src={`${process.env.NEXT_PUBLIC_MEDIA_URL}/${data?.image}`}
                                alt={data?.name}
                                layout='fill'
                            />
                        </div>
                    </a>
                </Link>
                <span
                    className={`category  ${String(data?.Category?.name).split(' ').at(0).toLowerCase()} rounded-pill`}
                >
                    {data?.Category?.name}
                </span>

                {COUPON && _currencyType === 'INR' ? (
                    <span className='kwn-ccdb rounded-pill text-dark d-flex align-items-center justify-content-center'>
                        <RupeeSymbol /> {COUPON?.discount} Off
                    </span>
                ) : null}
            </div>
            <ul className='meta-info list-unstyled m-0'>
                <li className='d-flex align-items-center text-nowrap'>
                    <img src='/kaarwan/icons/clock-7.svg' alt='days' />
                    <span>{data?.days}</span>
                </li>
                <li className='d-flex align-items-center text-nowrap'>
                    <img src='/kaarwan/icons/timer.svg' alt='duration' />
                    <span>{data?.hours}</span>
                </li>
                <li className='d-flex align-items-center text-nowrap'>
                    <img src='/kaarwan/icons/calendar-4.svg' alt='date' />
                    <span>{_IS_RECORDED ? 'Recorded' : date}</span>
                </li>
            </ul>
            <h3 className='title two-line-title mb-0'>
                <Link href={getWorkshopURL(data?.type, data?.name, data?.id, data?.url, _params)}>
                    <a>{getShortTitle(data?.shortTitleType, data?.shortTitle, data?.name, 2)}</a>
                </Link>
            </h3>

            <div className='pricing-details d-flex justify-content-between align-items-center'>
                <div className='price'>
                    <div className='d-flex align-items-baseline'>
                        <span className='offer-price'>
                            {getWsPricing(_currencyType, _INR_PRICE, _USD_PRICE, _SLASH_DOLLAR, _SLASH_AMOUNT)}
                        </span>
                        <span className='original-price'>
                            {getWsPricing(_currencyType, _INR_PRICE, _USD_PRICE, _SLASH_DOLLAR, _SLASH_AMOUNT, true)}
                        </span>
                    </div>
                    {_currencyType === 'INR' ? <div className='text-muted gst'>GST inclusive</div> : null}
                </div>
                <div className='cta'>
                    <Link
                        href={getPaymentUrl(
                            data?.name,
                            data?.Batches && data?.Batches?.length > 0 && data?.Batches[0]?.id,
                            data?.url,
                            null,
                            _params
                        )}
                    >
                        <a
                            className='kw-btn-orange d-flex align-items-center justify-content-center'
                            onClick={handleEvent}
                        >
                            Enroll now <i className='icon-arrow-right-line-right fw-bold'></i>
                        </a>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default CourseCardTwo;

export const RupeeSymbol = (props) => (
    <svg width={15} height={15} viewBox='0 0 15 15' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
        <path
            d='M6.7875 0.685603C7.15105 0.47571 7.59895 0.47571 7.9625 0.685603L12.8744 3.52151C13.238 3.73141 13.4619 4.1193 13.4619 4.53909V10.2109C13.4619 10.6307 13.238 11.0186 12.8744 11.2285L7.9625 14.0644C7.59895 14.2743 7.15105 14.2743 6.7875 14.0644L1.87556 11.2285C1.51202 11.0186 1.28806 10.6307 1.28806 10.2109V4.53909C1.28806 4.1193 1.51202 3.73141 1.87556 3.52151L6.7875 0.685603Z'
            stroke='black'
            strokeWidth={0.6}
        />
        <path
            d='M9.38961 4.83223H5.48935C5.33773 4.83223 5.21484 4.70934 5.21484 4.55771C5.21484 4.40609 5.33773 4.2832 5.48935 4.2832H9.38952C9.54114 4.2832 9.66403 4.40609 9.66403 4.55771C9.66403 4.70934 9.54114 4.83223 9.38961 4.83223Z'
            fill='black'
        />
        <path
            d='M9.38961 6.82441H5.48935C5.33773 6.82441 5.21484 6.70152 5.21484 6.5499C5.21484 6.39828 5.33773 6.27539 5.48935 6.27539H9.38952C9.54114 6.27539 9.66403 6.39828 9.66403 6.5499C9.66403 6.70152 9.54114 6.82441 9.38961 6.82441Z'
            fill='black'
        />
        <path
            d='M5.52939 8.68896C5.37777 8.68896 5.25488 8.56607 5.25488 8.41445C5.25488 8.26283 5.37777 8.13994 5.52939 8.13994C6.9983 8.13994 8.23927 7.37405 8.23927 6.46762C8.23927 5.561 6.9983 4.79512 5.52939 4.79512C5.37777 4.79512 5.25488 4.67223 5.25488 4.5206C5.25488 4.36898 5.37777 4.24609 5.52939 4.24609C7.35681 4.24609 8.7883 5.22189 8.7883 6.46762C8.7883 7.71317 7.35681 8.68896 5.52939 8.68896Z'
            fill='black'
        />
        <path
            d='M8.46252 11.9164C8.38941 11.9164 8.31648 11.8874 8.2624 11.8299L5.36356 8.7457C5.25971 8.63517 5.26511 8.46149 5.37555 8.35764C5.486 8.25387 5.65967 8.25927 5.76362 8.36962L8.66255 11.4538C8.7664 11.5644 8.761 11.7381 8.65056 11.8419C8.59749 11.8917 8.52996 11.9164 8.46252 11.9164Z'
            fill='black'
        />
    </svg>
);
