import React from 'react';
import moment from 'moment';
import Link from 'next/link';
import Image from 'next/image';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { getPrices, getShortTitle, getWorkshopURL, getWsPricing } from '../../utils';
import { selectCurrency } from '../../redux/slice/currencySlice';
import { RupeeSymbol } from './CourseCardTwo';
import { utmClickCount } from '../../services/dashboardServices';

const CourseCardFive = ({ data, setOpen, setCourse, type = 2 }) => {
    const { currency: _currencyType } = useSelector(selectCurrency);

    const { _INR_PRICE, _USD_PRICE, _SLASH_DOLLAR, _SLASH_AMOUNT, _IS_RECORDED } = getPrices(data);


    const date =
        moment(data?.Batches && data?.Batches[0]?.startDate)
            .format('MMM DD')
            .toString()
            .toLowerCase() === 'invalid date'
            ? 'Coming soon'
            : moment(data?.Batches[0]?.startDate).format('MMM DD');



    const COUPON = data?.CouponDetails && data?.CouponDetails.length ? data?.CouponDetails[0] : null;

    const _VALUE = _currencyType === 'INR' ? _INR_PRICE : _USD_PRICE;
    const _CURRENCY = _currencyType === 'INR' ? 'INR' : 'USD';


    const router = useRouter();

    let _params = '';
    Object.entries(router.query)
        .filter((item) => item[0] !== 'paymentId')
        .filter((item) => item[0] !== 'id')
        .forEach((item) => {
            _params += `&${item[0]}=${item[1]}`;
        });

    const handleClickEvent = async (_wsId, _utmSource, _utmMedium, _utmCampaign, _utmType, _userId = null) => {
        const _payload = {
            wsId: _wsId,
            source: _utmSource,
            medium: _utmMedium,
            campaign: _utmCampaign,
            type: _utmType
        }
        if (_userId) {
            _payload.userId = _userId;
        }
        try {
            await utmClickCount(_payload)
        } catch (error) {
            console.log("Something went wrong::>");
        }
    }


    const openHandle = () => {
        setOpen(true)
        setCourse({
            name: data?.name,
            id: data?.id,
            kylasProductId: data?.kylasProductId
        });

        handleClickEvent(data.id, 'course card', 'button', 'Get brochure', 20)
    }



    const handleEvent = () => {
        !!window.fbq &&
            window.fbq('track', 'AddToCart', {
                content_name: data?.name,
                value: _VALUE,
                content_type: 'Product',
                currency: _CURRENCY,
            });
        window.gtag('event', 'add_to_cart', {
            currency: _CURRENCY,
            value: _VALUE,
            items: [
                {
                    item_name: data?.name,
                    price: _VALUE,
                    quantity: 1,
                },
            ],
            event_category: 'button',
            event_label: 'add_to_cart',
        });
    };




    return (
        <div className={`course-card-5 ${type === 1 ? 'masterclass' : ''}`}>
            <div className="image-wrapper overflow-hidden">
                <Link href={getWorkshopURL(data?.type, data?.name, data?.id, data?.url, _params)}>
                    <a onClick={handleEvent}>
                        <img
                            src={`${process.env.NEXT_PUBLIC_MEDIA_URL}/${data?.image}`}
                            alt={data?.name}
                        />
                    </a>
                </Link>
                <span
                    className={`category ${String(data?.Category?.name).split(' ').at(0).toLowerCase()} rounded-pill`}
                >
                    {data?.Category?.name}
                </span>
                {COUPON && _CURRENCY === 'INR' ? (
                    <span className='kwn-ccdb rounded-pill text-dark d-flex align-items-center justify-content-center'>
                        <RupeeSymbol /> {COUPON?.discount} Off
                    </span>
                ) : null}
            </div>
            <ul className="meta-info m-0 p-0 d-flex align-items-center list-unstyled">
                <li className='d-flex align-items-center'>
                    <span className="icon">
                        <img src='/kaarwan/icons/clock-7.svg' alt='days' />
                    </span>
                    <span className="text">{data?.days}</span>
                </li>
                <li className='d-flex align-items-center'>
                    <span className="icon">
                        <img src='/kaarwan/icons/timer.svg' alt='duration' />
                    </span>
                    <span className="text">{data?.hours}</span>
                </li>
                <li className='d-flex align-items-center'>
                    <span className="icon">
                        <img src='/kaarwan/icons/calendar-4.svg' alt='date' />
                    </span>
                    <span className="text">{_IS_RECORDED ? 'Recorded' : date}</span>
                </li>
            </ul>
            <Link href={getWorkshopURL(data?.type, data?.name, data?.id, data?.url, _params)}>
                <a onClick={handleEvent}>
                    <h3 className="course-name two-line-title">
                        {getShortTitle(data?.shortTitleType, data?.shortTitle, data?.name, data?.type)}
                    </h3>
                </a>
            </Link>
            <div className="prices-wrapper">
                <span className="offer-price">
                    {getWsPricing(_currencyType, _INR_PRICE, _USD_PRICE, _SLASH_DOLLAR, _SLASH_AMOUNT)}

                </span>
                <span className="og-price">
                    <strike>{getWsPricing(_currencyType, _INR_PRICE, _USD_PRICE, _SLASH_DOLLAR, _SLASH_AMOUNT, true)}</strike>
                </span>
            </div>
            <span className="gst-incl d-block">
                GST inclusive
            </span>

            <div className="cta-wrapper d-flex align-items-center justify-content-evenly">
                {type === 2 ? <div className='w-100'>
                    <button type="button" className='bg-transparent border-0 d-flex align-items-center btn-brochure text-nowrap' onClick={openHandle}>
                        <Image src='/constant/course/brochure-download-icon.webp' width={20} height={20} alt='brochure-download' />
                        <span className='text'>
                            Get Brochure
                        </span>
                    </button>
                </div> : null}
                <div className="w-100">
                    <Link href={getWorkshopURL(2, data?.name, data?.id, data?.url, _params)}>
                        <a className='w-100 rounded-pill kw-btn-orange d-flex align-items-center text-nowrap justify-content-center' onClick={handleEvent}>
                            <span className="text-white">Explore Program</span> <Arrow />
                        </a>
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default CourseCardFive


const Arrow = (props) => (
    <svg
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M4.16797 10H15.8346"
            stroke="white"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M10 4.16406L15.8333 9.9974L10 15.8307"
            stroke="white"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);