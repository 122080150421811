import React from 'react';

const InteriorDesignIcon = () => {
  return (
    <svg width='17' height='21' viewBox='0 0 17 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M12.4748 12.3467C12.7037 12.3467 12.8835 12.5305 12.8835 12.7553V18.4242C12.8835 19.63 11.9026 20.6109 10.6968 20.6109C9.48703 20.6109 8.506 19.63 8.506 18.4242V12.7553C8.506 12.5305 8.68989 12.3467 8.91467 12.3467H10.3559V10.2643C10.3559 10.1341 10.4178 10.0118 10.5226 9.93484L15.7707 6.08405C16.0322 5.85157 16.1822 5.51764 16.1822 5.16766C16.1822 4.49129 15.6321 3.94096 14.9557 3.94096H14.9408C14.8567 4.46064 14.6155 4.9427 14.2326 5.32894C13.7504 5.80706 13.1127 6.06881 12.4383 6.06881H2.7599C1.36215 6.06881 0.22168 4.92851 0.22168 3.53058C0.22168 2.85628 0.487339 2.21854 0.965616 1.7363C1.4475 1.25783 2.0852 0.992188 2.75955 0.992188H12.4384C13.6974 0.992188 14.7445 1.91817 14.9407 3.12328H14.956C16.0829 3.12328 17 4.04032 17 5.16748C17 5.75061 16.7501 6.30743 16.2844 6.71903L11.1739 10.4713V12.3466L12.4748 12.3467Z'
        fill='#D94AE7'
      />
    </svg>
  );
};

export default InteriorDesignIcon;
